//  Duad Telecom
export default {
    BackendURL: 'https://s.dtcom.eastdevs.com/',
    LogoImage: '/static/logos/dtcom/logo.png',
    FaviconImage: '/static/logos/dtcom/favicon.png',
    UserLoginImage: '/static/logos/dtcom/login.png',
    PRIMARY: {
        lighter: '#AE4DAE',
        light: '#A233A2',
        lightest: '#C580C5',
        main: '#8B008B',
        dark: '#6F006F',
        darker: '#610061',
        contrastText: '#fff',
    },

    SECONDARY: {
        lighter: '#EED1D7',
        light: '#EBCAD0',
        main: '#E8C2C9',
        dark: '#CEA9B0',
        darker: '#B58F96',
        contrastText: '#fff',
    },
};
